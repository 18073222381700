export function parseDate( value, opciones : Intl.DateTimeFormatOptions ) {
  if (!value) return ''
  const date = new Date(value)
  return date.toLocaleString(['es-ES'], {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    ...opciones
    // hour: '2-digit',
    // minute: '2-digit',
  })
}

type ValueType = string | boolean | null | number
type FilterType = string | ValueType[] | boolean

export function filterColumn(value : ValueType, filter : FilterType, dataType = "text") : Boolean {
  if (
    filter === "" ||
    filter === null ||
    filter === undefined ||
    (Array.isArray(filter) && filter.length == 0)
  )
    return true;
  if (filter === "-") return value === null;
  if (value === "" || value === null || value === undefined) return false;

  const filterText = (filter : string, value : ValueType) => {
    value = String(value)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    filter = String(filter)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const isOperation = /=|<|>|!/.test(filter[0]);
    if (isOperation) {
      switch (filter[0]) {
        case "!":
          return !value.includes(filter.substring(1));
        case "=":
          return value == filter.substring(1);
        case ">":
          return Number(value) >= Number(filter.substring(1));
        case "<":
          return Number(value) <= Number(filter.substring(1));
        default:
          return false;
      }
    } else {
      return value.includes(filter);
    }
  };
  const filterDate = (filter : string[], value : ValueType) => {
    filter = filter.map((f) => {
      let temp = new Date(f);
      temp.setHours(0, 0, 0, 0);
      return temp.getTime();
    });
    value = new Date(value).getTime();
    let max = Math.max(...filter);
    let min = Math.min(...filter);
    return max >= value && value >= min;
  };
  const filterSelect = (filter : ValueType[], value : ValueType) => {
    if (filter.length > 1) return filter.includes(value);
    else return filterText("=" + filter[0], value);
  };
  const filterBool = (filter : boolean, value : ValueType) => filter == Boolean(value);

  switch (dataType) {
    case "select":
      return filterSelect(filter, value);
    case "date":
      return filterDate(filter, value);
    case "bool":
      return filterBool(filter, value);
    case "text":
    default:
      return filterText(filter, value);
  }
};