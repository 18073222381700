<template>
	<v-container fluid>
		<!-- <div class="d-flex py-2">
			<v-spacer />
			<v-btn
				:disabled="selected.length == 0"
				:loading="!!downloadingFile"
				small
				color="primary"
				text
				@click="downloadSelected"
			>
				<v-icon left>mdi-download</v-icon>
				Descargar seleccionados
			</v-btn>
		</div> -->
		<v-card>
			<app-table :headers="headers" :items="leads" v-model="selected" item-key="reflead" show-select
				class="fixed-checkbox" :single-select="false" checkbox-color="secondary"
				:footer-props="{ showFirstLastPage: true, showCurrentPage: true }">
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.fecha="{ item }">
					{{ parseDate(item.fecha, false, true, true) }}
				</template>

				<template v-slot:item.fotofactura="{ item }">
					<v-btn small @click.stop="verDocumento(item)" :elevation="0" color="primary" v-if="item.fotofactura">
						{{ $t('common.ver') }}
					</v-btn>
				</template>

			</app-table>
		</v-card>

	</v-container>
</template>

<script setup  >
import { ref, watch, computed } from "vue";
import $axios from "@/utils/axiosWrapper.ts";
import { parseDate, perColumnFilter, downloadFile } from "@/utils/index.js";
import tableFilters from '@/components/TableFilters.vue'
import AppTable from "@/components/AppTable.vue"
import mime from 'mime-types';
import { getCurrentInstance } from 'vue'

const { data: leads } = $axios('leads', { default: () => ([]) })
const selected = ref([])
const inlineFilters = ref({})

const headers = [
	{ text: 'Nombre', value: 'nombre' },
	{ text: 'Fecha', value: 'fecha' },
	{ text: 'Telefono', value: 'telefono' },
	{ text: 'Email', value: 'correo' },
	{ text: 'Factura', value: 'fotofactura' },
].map(h => ({
	class: "text-no-wrap sticky-header",
	cellClass: "pa-2 text-no-wrap",
	dataType: "text",
	filter: (value) =>
		perColumnFilter(
			value,
			inlineFilters.value[h.value],
			h.dataType
		), ...h
})
)

const root = getCurrentInstance()

async function verDocumento(item) {
	try {

		const { data } = await axios({
			method: "GET",
			url: `leads/factura/${item.fotofactura}`,
			responseType: "blob",
			transform: (res) => {
				return res
			}
		})
		let ext = item.fotofactura.split('.')[item.fotofactura.split('.').length - 1]

		downloadFile(new File([data], `Factura_${item.nombre}.${ext}`, { type: mime.lookup(item.fotofactura) }), true);
	} catch {
		root.proxy.$root.$emit(
			"snack",
			root.proxy.$i18n.t('documentacion.not_found'), { color: 'error' }
		);
		return
	}

}

</script>
